import React, { useState } from "react";

import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ResetOverlay from "../utils/ResetOverlay";
import FormFieldError from "../../utils/FormFieldError";
import FormFieldLabel from "../../utils/FormFieldLabel";
import PhoneNumber, {
  stripPhoneNumberForSubmission
} from "../../utils/PhoneNumber";
import { newApplication } from "../../utils/api_services";
import AutoCompleteAddress from "../../utils/AutoCompleteAddress";

import { parsePhoneNumberFromString } from "libphonenumber-js";
import * as Yup from "yup";

import "bootstrap/dist/css/bootstrap.css";
import "./NewApplication.css";

const NewApplication = ({ authToken, clearToken }) => {
  const navigate = useNavigate();

  const [mailingAddress, setMailingAddress] = useState({});
  let [step, setStep] = useState(1);

  const getISOString = (year, month, day) => {
    let result = [`${year}`];

    month++;

    if (month < 10) {
      result.push(`0${month}`);
    } else {
      result.push(`${month}`);
    }

    if (day < 10) {
      result.push(`0${day}`);
    } else {
      result.push(`${day}`);
    }

    return result.join("-");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: "",
      dob: "",
      mailing_address_placeholder: "",
      phone: "",
      gender: "",
      non_field_errors: null
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      dob: Yup.date().required("Date of birth is required"),
      phone: Yup.string()
        .required("Phone number is required")
        .test("isValidPhoneNumber", (value, context) => {
          if (!value)
            return context.createError({ message: "Phone number is required" });

          // Remove all spaces from the value
          const strippedValue = value.replace(/\s+/g, "");

          // Check for 8-digit landline numbers (with or without spaces in the original input)
          if (/^\d{8}$/.test(strippedValue)) {
            return context.createError({
              message: "If using a landline, please provide an area code"
            });
          }

          try {
            const phoneNumber = parsePhoneNumberFromString(value, "AU");
            if (phoneNumber && phoneNumber.isValid()) {
              return true;
            }
            return context.createError({ message: "Invalid phone number" });
          } catch (error) {
            return context.createError({ message: "Invalid phone number" });
          }
        }),
      mailing_address_placeholder:
        step === 2 ? Yup.string().required("Mailing address is required") : ""
    }),
    onSubmit: async (
      values,
      { setSubmitting, setFieldError, setFieldValue, resetForm }
    ) => {
      if (step === 1) {
        setStep(2);
        return;
      }

      let dobValue = values.dob;
      if (dobValue !== null && dobValue !== undefined) {
        dobValue = getISOString(
          dobValue.getFullYear(),
          dobValue.getMonth(),
          dobValue.getDate()
        );
      }

      const strippedPhoneNumber = stripPhoneNumberForSubmission(values.phone);

      console.log(dobValue);
      console.log("strippedPhoneNumber", strippedPhoneNumber);

      const [isValid, responseData] = await newApplication(
        authToken,
        values.email,
        values.first_name,
        values.last_name,
        dobValue,
        mailingAddress,
        strippedPhoneNumber,
        values.gender
      );

      if (isValid === null) {
        clearToken();
        navigate("/", { replace: true });
        window.location.reload(true);
      }

      setFieldValue("non_field_errors", null, false);

      if (!isValid) {
        for (const key in responseData) {
          setFieldError(key, responseData[key]);
          if (
            [
              "email",
              "phone",
              "dob",
              "first_name",
              "last_name",
              "gender"
            ].includes(key)
          ) {
            setStep(1);
          }
        }
      } else {
        navigate("/typeform", {
          state: {
            formId: responseData.form_id,
            initiator_email: responseData.initiator_email,
            token: responseData.token,
            email: responseData.email,
            first_name: responseData.first_name,
            last_name: responseData.last_name,
            phone: responseData.phone,
            gender: responseData.gender
          },
          replace: true
        });
      }

      setSubmitting(false);
    }
  });

  const goBack = () => {
    setStep(step - 1);
  };

  return (
    <div className="bg-custom-primary content-centered">
      <ResetOverlay idleTimeoutThreshold={2 * 60 * 1000} resetTimeout={step} />
      <svg className="d-none">
        <symbol
          id="round-user-icon"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <circle cx="16" cy="16" r="16" fill="#060A20"></circle>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.4116 12.0758C20.4116 14.5234 18.4492 16.4859 15.9999 16.4859C13.5515 16.4859 11.5883 14.5234 11.5883 12.0758C11.5883 9.62831 13.5515 7.66663 15.9999 7.66663C18.4492 7.66663 20.4116 9.62831 20.4116 12.0758ZM15.9999 24.3333C12.3852 24.3333 9.33325 23.7458 9.33325 21.4791C9.33325 19.2116 12.4044 18.6449 15.9999 18.6449C19.6154 18.6449 22.6666 19.2324 22.6666 21.4991C22.6666 23.7666 19.5954 24.3333 15.9999 24.3333Z"
            fill="white"
          ></path>
        </symbol>
      </svg>
      <svg className="d-none">
        <symbol
          id="logo"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 118 12"
        >
          <path
            d="M10.1295 1.26526C10.8383 1.66526 11.4107 2.27617 11.8401 3.09798C12.2695 3.9198 12.4875 4.93071 12.4875 6.13798C12.4875 7.34526 12.2695 8.35617 11.8401 9.17798C11.4107 9.9998 10.8383 10.6107 10.1295 11.0107C9.42072 11.4107 8.65061 11.6143 7.81235 11.6143H0.397461V0.661621H7.81235C8.65061 0.661621 9.42072 0.865258 10.1295 1.26526ZM6.38798 8.42162C6.87867 8.42162 7.28758 8.38526 7.61471 8.30526C7.93502 8.23253 8.21444 8.02889 8.43934 7.69435C8.66424 7.36708 8.7801 6.84344 8.7801 6.13798C8.7801 5.42526 8.66424 4.90889 8.43934 4.58162C8.21444 4.25435 7.93502 4.05071 7.61471 3.97071C7.2944 3.89798 6.88549 3.85435 6.38798 3.85435H3.96179V8.41435H6.38798V8.42162Z"
            fill="white"
          ></path>
          <path
            d="M13.9121 0.661621H17.4764V11.6143H13.9121V0.661621Z"
            fill="white"
          ></path>
          <path
            d="M22.6078 8.01419C22.6078 8.79237 23.5415 9.1851 25.4157 9.1851C26.1176 9.1851 26.656 9.14873 27.0172 9.08328C27.3853 9.01783 27.6306 8.93055 27.7533 8.81419C27.8759 8.7051 27.9373 8.55964 27.9373 8.3851C27.9373 8.17419 27.8691 8.01419 27.7396 7.91964C27.6033 7.8251 27.3444 7.7451 26.9559 7.67964C26.5674 7.61419 25.9132 7.54874 24.9863 7.47601C23.5892 7.36692 22.4579 7.17055 21.5992 6.90146C20.7405 6.6251 20.0999 6.23964 19.6773 5.73055C19.2548 5.22146 19.0435 4.56692 19.0435 3.75237C19.0435 2.65419 19.5615 1.81783 20.5974 1.23601C21.6333 0.65419 23.0508 0.363281 24.8432 0.363281C26.8605 0.363281 28.4075 0.683281 29.4843 1.31601C30.5611 1.94874 31.1404 2.87237 31.2154 4.09419H27.5079C27.5079 3.73783 27.2967 3.48328 26.8673 3.33055C26.4379 3.17783 25.7633 3.1051 24.8432 3.1051C24.0322 3.1051 23.487 3.16328 23.1871 3.27964C22.8941 3.39601 22.7441 3.56328 22.7441 3.77419C22.7441 3.94874 22.8123 4.07964 22.9418 4.17419C23.0781 4.26874 23.3371 4.35601 23.7323 4.42874C24.1276 4.50146 24.7342 4.57419 25.552 4.65419C27.0377 4.7851 28.1826 4.9451 28.9868 5.13419C29.7978 5.32328 30.4384 5.65055 30.9155 6.13055C31.3926 6.61055 31.6379 7.30873 31.6379 8.24692C31.6379 9.57782 31.1268 10.5233 30.0977 11.0833C29.0686 11.6433 27.5556 11.9269 25.552 11.9269C23.412 11.9269 21.7832 11.6215 20.6723 11.0178C19.5546 10.4069 18.9617 9.41055 18.9004 8.02146H22.6078V8.01419Z"
            fill="white"
          ></path>
          <path
            d="M44.2121 1.76708C44.9414 2.50162 45.3026 3.5198 45.3026 4.81435C45.3026 6.11617 44.9414 7.12708 44.2121 7.86162C43.4829 8.59617 42.4811 8.96708 41.1998 8.96708H36.6405V11.6143H33.0762V0.661621H41.1998C42.4811 0.661621 43.4829 1.03253 44.2121 1.76708ZM40.3411 5.77435C40.6274 5.77435 40.8523 5.7598 41.0158 5.73071C41.1794 5.70162 41.3225 5.61435 41.4315 5.47617C41.5406 5.33799 41.5951 5.1198 41.5951 4.81435C41.5951 4.50889 41.5406 4.29071 41.4315 4.15253C41.3225 4.01435 41.1862 3.93435 41.0158 3.89798C40.8523 3.86889 40.6205 3.85435 40.3411 3.85435H36.6337V5.77435H40.3411Z"
            fill="white"
          ></path>
          <path
            d="M57.2764 0.661133V3.54841H50.2909V4.69022H56.9902V7.5775H50.2909V8.71931H57.2764V11.6066H46.7266V0.661133H57.2764Z"
            fill="white"
          ></path>
          <path
            d="M68.9915 7.90477V0.661133H72.5558V11.6139H67.2809L62.4081 4.37022V11.6139H58.8438V0.661133H64.1187L68.9915 7.90477Z"
            fill="white"
          ></path>
          <path
            d="M77.6879 8.01419C77.6879 8.79237 78.6216 9.1851 80.4958 9.1851C81.1977 9.1851 81.7361 9.14873 82.0973 9.08328C82.4653 9.01783 82.7107 8.93055 82.8334 8.81419C82.956 8.7051 83.0174 8.55964 83.0174 8.3851C83.0174 8.17419 82.9492 8.01419 82.8197 7.91964C82.6834 7.8251 82.4245 7.7451 82.036 7.67964C81.6475 7.61419 80.9933 7.54874 80.0664 7.47601C78.6693 7.36692 77.538 7.17055 76.6793 6.90146C75.8206 6.6251 75.1799 6.23964 74.7574 5.73055C74.3349 5.22146 74.1236 4.56692 74.1236 3.75237C74.1236 2.65419 74.6415 1.81783 75.6774 1.23601C76.7133 0.65419 78.1309 0.363281 79.9233 0.363281C81.9406 0.363281 83.4876 0.683281 84.5644 1.31601C85.6412 1.94874 86.2205 2.87237 86.2955 4.09419H82.588C82.588 3.73783 82.3767 3.48328 81.9474 3.33055C81.518 3.17783 80.8433 3.1051 79.9233 3.1051C79.1123 3.1051 78.5671 3.16328 78.2672 3.27964C77.9742 3.39601 77.8242 3.56328 77.8242 3.77419C77.8242 3.94874 77.8924 4.07964 78.0219 4.17419C78.1582 4.26874 78.4171 4.35601 78.8124 4.42874C79.2077 4.50146 79.8142 4.57419 80.6321 4.65419C82.1178 4.7851 83.2627 4.9451 84.0669 5.13419C84.8779 5.32328 85.5185 5.65055 85.9956 6.13055C86.4727 6.61055 86.718 7.30873 86.718 8.24692C86.718 9.57782 86.2069 10.5233 85.1778 11.0833C84.1487 11.6433 82.6357 11.9269 80.6321 11.9269C78.4921 11.9269 76.8633 11.6215 75.7524 11.0178C74.6347 10.4069 74.0418 9.41055 73.9805 8.02146H77.6879V8.01419Z"
            fill="white"
          ></path>
          <path
            d="M98.6993 0.661621V3.54889H91.7137V4.69071H98.413V7.57798H91.7137V8.7198H98.6993V11.6071H88.1494V0.661621H98.6993Z"
            fill="white"
          ></path>
          <path
            d="M109.999 1.26526C110.707 1.66526 111.28 2.27617 111.709 3.09798C112.139 3.9198 112.357 4.93071 112.357 6.13798C112.357 7.34526 112.139 8.35617 111.709 9.17798C111.28 9.9998 110.707 10.6107 109.999 11.0107C109.29 11.4107 108.52 11.6143 107.681 11.6143H100.267V0.661621H107.681C108.52 0.661621 109.29 0.865258 109.999 1.26526ZM106.257 8.42162C106.748 8.42162 107.157 8.38526 107.484 8.30526C107.804 8.23253 108.084 8.02889 108.308 7.69435C108.533 7.36708 108.649 6.84344 108.649 6.13798C108.649 5.42526 108.533 4.90889 108.308 4.58162C108.084 4.25435 107.804 4.05071 107.484 3.97071C107.164 3.89798 106.755 3.85435 106.257 3.85435H103.831V8.41435H106.257V8.42162Z"
            fill="white"
          ></path>
          <path
            d="M113.638 11.6144V8.87988H117.059V11.6144H113.638Z"
            fill="white"
          ></path>
        </symbol>
      </svg>
      <div className="container">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <div className="card register-card">
              <div className="card-body py-0 ps-0">
                <div className="row">
                  <div className="col-5 col-md-4 pr-0">
                    <div className="register-header">
                      <svg className="svg-logo-icon">
                        <use xlinkHref="#logo" />
                      </svg>
                    </div>
                  </div>
                  <div className="col-7 col-md-8 py-5">
                    <div className="text-center">
                      <p>
                        <svg className="svg-icon">
                          <use xlinkHref="#round-user-icon" />
                        </svg>
                      </p>
                      <p className="fw-bold">Create New Account</p>
                    </div>
                    <div className="my-2">&nbsp;</div>
                    <div>
                      <ul className="sign-up-steps">
                        <li
                          className={`sign-up-steps__item ${
                            step === 1 ? "active-step" : "success-step"
                          }`}
                        >
                          Personal Information
                        </li>
                        <li
                          className={`sign-up-steps__item ${
                            step === 2 ? "active-step" : ""
                          }`}
                        >
                          Address
                        </li>
                      </ul>
                    </div>
                    <div className="my-2">&nbsp;</div>
                    <form onSubmit={formik.handleSubmit}>
                      {formik.errors.non_field_errors && (
                        <div>
                          <div className="alert alert-danger">
                            {formik.errors.non_field_errors}
                          </div>
                          <div className="my-1">&nbsp;</div>
                        </div>
                      )}
                      {step === 1 && (
                        <div>
                          <FormFieldLabel label="Email" isRequired={true} />
                          <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            autoComplete="false"
                            className={
                              "form-control form_control mb-3" +
                              (formik.errors.email ? "is-invalid" : "")
                            }
                            placeholder="patient@patient.com"
                            required
                          />
                          <FormFieldError formik={formik} fieldName="email" />
                          <FormFieldLabel
                            label="First Name"
                            isRequired={true}
                          />
                          <input
                            id="first_name"
                            name="first_name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.first_name}
                            autoComplete="false"
                            className={
                              "form-control form_control mb-3" +
                              (formik.errors.first_name ? "is-invalid" : "")
                            }
                            placeholder="John"
                            required
                          />
                          <FormFieldError
                            formik={formik}
                            fieldName="first_name"
                          />
                          <FormFieldLabel label="Last Name" isRequired={true} />
                          <input
                            id="last_name"
                            name="last_name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.last_name}
                            autoComplete="false"
                            className={
                              "form-control form_control mb-3" +
                              (formik.errors.last_name ? "is-invalid" : "")
                            }
                            placeholder="John"
                            required
                          />
                          <FormFieldError
                            formik={formik}
                            fieldName="last_name"
                          />
                          <FormFieldLabel
                            label="Phone Number"
                            isRequired={true}
                          />
                          <PhoneNumber name="phone" formik={formik} />
                          <FormFieldError formik={formik} fieldName="phone" />
                          <FormFieldLabel
                            label="Date of Birth"
                            isRequired={true}
                          />
                          <ReactDatePicker
                            id="dob"
                            name="dob"
                            selected={formik.values.dob}
                            onChange={(date) => {
                              formik.setFieldValue("dob", date);
                            }}
                            dateFormat="dd-MM-yyyy"
                            className={
                              "form-control form_control mb-3" +
                              (formik.errors.dob ? "is-invalid" : "")
                            }
                            required
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="DD-MM-YYYY"
                            withPortal
                            disabledKeyboardNavigation
                            maxDate={new Date()}
                            autoFocus={false}
                            onFocus={(e) => (e.target.readOnly = true)}
                          />
                          <FormFieldError formik={formik} fieldName="dob" />
                          <FormFieldLabel
                            label="Gender"
                            className="mb-3"
                            isRequired={true}
                          />
                          <label className="form-label px-2">
                            <input
                              id="gender"
                              name="gender"
                              type="radio"
                              onChange={formik.handleChange}
                              value="male"
                              checked={formik.values.gender === "male"}
                              autoComplete="false"
                              className={
                                "mx-2" +
                                (formik.errors.gender ? "is-invalid" : "")
                              }
                            />
                            Male
                          </label>
                          <label className="form-label px-2">
                            <input
                              id="gender"
                              name="gender"
                              type="radio"
                              onChange={formik.handleChange}
                              value="female"
                              checked={formik.values.gender === "female"}
                              autoComplete="false"
                              className={
                                "mx-2" +
                                (formik.errors.gender ? "is-invalid" : "")
                              }
                            />
                            Female
                          </label>
                          <label className="form-label px-2">
                            <input
                              id="gender"
                              name="gender"
                              type="radio"
                              onChange={formik.handleChange}
                              value="other"
                              checked={formik.values.gender === "other"}
                              autoComplete="false"
                              className={
                                "mx-2" +
                                (formik.errors.gender ? "is-invalid" : "")
                              }
                            />
                            Other
                          </label>
                          <FormFieldError formik={formik} fieldName="gender" />
                        </div>
                      )}
                      {step === 2 && (
                        <AutoCompleteAddress
                          setMailingAddress={setMailingAddress}
                          fieldName="mailing_address_placeholder"
                          formik={formik}
                        />
                      )}
                      <div className="my-3">&nbsp;</div>
                      {step > 1 ? (
                        <div className="row">
                          <div className="col-4">
                            <button
                              className="btn main_btn w-100"
                              type="button"
                              onClick={goBack}
                            >
                              Back
                            </button>
                          </div>
                          <div className="col-8">
                            <button
                              type="submit"
                              className="btn main_btn w-100"
                              disabled={formik.isSubmitting}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          {step > 1 ? (
                            <div className="col-4">
                              <button
                                className="btn main_btn w-100"
                                type="button"
                                onClick={goBack}
                              >
                                Back
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="col">
                            <button
                              type="submit"
                              className="btn main_btn w-100"
                              disabled={formik.isSubmitting}
                            >
                              {step !== 2 ? "Next" : "Submit"}
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1"></div>
        </div>
      </div>
    </div>
  );
};

export default NewApplication;
